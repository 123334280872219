/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// debulked onresize handler
// https://github.com/louisremi/jquery-smartresize

(function($) {

  

  var on_resize= function (c,t){onresize=function(){clearTimeout(t);t=setTimeout(c,100);};return c;};

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Hero = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        if ($('.grid .item .wrapper').length) {
          $('.grid .item .wrapper').matchHeight({ byRow: true, property: 'height', target: null, remove: false });
        }
        if ($('.woocommerce-cart').length) {
          $('.col2 .cart-segment').matchHeight({ byRow: true, property: 'height', target: null, remove: false, mq: "(min-width: 900px)" });
          $('.col2 .equal').matchHeight({ byRow: true, property: 'height', target: null, remove: false, mq: "(min-width: 900px)" });
        }
        if ($('.woocommerce-checkout').length) {
          $('.col2 .equal').matchHeight({ byRow: true, property: 'height', target: null, remove: false, mq: "(min-width: 900px)" });
        }

        // UGLY lazy load re-layout fix
        $(".lazy").on("lazyloaded", function (event) {
          $('.even-grid .item').matchHeight();
          if ($('.grid .item .wrapper').length) {
            $('.grid .item .wrapper').matchHeight({ byRow: true, property: 'height', target: null, remove: false });
          }
          if ($('.woocommerce-cart').length) {
            $('.col2 .cart-segment').matchHeight({ byRow: true, property: 'height', target: null, remove: false, mq: "(min-width: 900px)" });
          }
          if ($('.woocommerce-checkout').length) {
            $('.col2 .equal, .col2 .equal').matchHeight({ byRow: true, property: 'height', target: null, remove: false, mq: "(min-width: 900px)" });
          }
          $container = $('.masonry-grid');
          if ($container.length) {
            $container.each(function () {
              var msnry = Masonry.data($(this)[0]);
              msnry.layout();
            });
          }
        });

        // SELECT 2

        if ($('#cart_add_choose').length) {
          
          var formatState = function (data) {
            if (!data.id) {
              return data.text;
            }
            var $elem = $(data.element);
            var $img = $('<img />', {
              src: $elem.data('img-src')
            });
            var $title = $('<h3 />',
              {
                text: $elem.data('title'),
              }
            );
            var $subtitle = $('<h4 />', {
              text: $elem.data('subtitle')
            }

            );
            var $opt = $img.add($title).add($subtitle);
            return $opt;
          };
          var formatState2 = function (data) {
            if (!data.id) {
              return data.text;
            }
            var $elem = $(data.element);
            var $img = $('<img />', {
              src: $elem.data('img-src'),
              style: 'max-width:40px;'
            });
            var $title = $('<h3 />',
              {
                text: $elem.data('title'),
                // style: 'max-width:100px;'
              }
            );
            var $opt = $img.add($title);
            return $opt;
          };

          $("#choose-receiver").select2({
            templateResult: formatState,
            templateSelection: formatState2,
            minimumResultsForSearch: 12
          }).change(
            function (e) {
              $elem = $(e.target).find(':selected');
              $max = $elem.data('max');
              $id = $elem.val();
              $target = $('#new-quantity input');
              if ($target.val() > $max) {
                $target.val($max);
              }
              $target.attr('max', $max);
              $('#product_id_add').val($id);
            }
          );

        }

        // EXPAND 'Meer geven'
        if ($('.cart-other #give-more #cart_add_choose').length) {
          $btn_receiver = $('#give-more .header');
          $btn_receiver.on('click touchstart', function () {
            $btn = $(this);
            $collapsible = $btn.closest('.collapsible');
            $collapsible.find('.wrap-collapse').slideToggle("slow", function () {
              // Animation complete.
            });
          });
        }


        // FAQ
        if ($('.faq_question').length) {
          $('.faq_question').on('click touchstart', function () {
            if ($(this).parent().is('.open')) {
              $(this).closest('.faq').find('.faq_answer_container').animate({ 'height': '0' }, 500);
              $(this).closest('.faq').removeClass('open');
              $(this).parent().find('.accordion-button-icon').removeClass('fa-minus').addClass('fa-plus');
            }
            else {
              var newHeight = $(this).closest('.faq').find('.faq_answer').show().outerHeight(true) +30+ 'px';
              $(this).closest('.faq').find('.faq_answer_container').animate({ 'height': newHeight }, 500);
              $(this).closest('.faq').addClass('open');
              $(this).parent().find('.accordion-button-icon').removeClass('fa-plus').addClass('fa-minus');
            }
          });
        }

        jQuery(document).ajaxComplete(function () {
          jQuery("#billing_address_1_field").removeClass('woocommerce-invalid-required-field');
          jQuery("#billing_address_1_field").removeClass('woocommerce-invalid');
          jQuery("#billing_address_1_field").removeClass('validate-required');
          jQuery("#billing_address_1_field > label > abbr").remove();
          jQuery("#billing_postcode_field").removeClass('woocommerce-invalid-required-field');
          jQuery("#billing_postcode_field").removeClass('woocommerce-invalid');
          jQuery("#billing_postcode_field").removeClass('validate-required');
          jQuery("#billing_postcode_field > label > abbr").remove();
          jQuery("#billing_city_field").removeClass('woocommerce-invalid-required-field');
          jQuery("#billing_city_field").removeClass('woocommerce-invalid');
          jQuery("#billing_city_field").removeClass('validate-required');
          jQuery("#billing_city_field > label > abbr").remove();
        });

        // ajax stuff
        $('.remove-booking').click(function (e) {
            var $removeBtn = $(this);
            var $removeItem = $removeBtn.closest('.item');
            var product_key = $removeBtn.data('product_key');
            $.ajax({
              type: "POST",
              url: ajax_url,
              data: { action: 'remove_item_from_cart', 'product_key': product_key },
              success: function (res) {
                if (res) {
                  $removeItem.remove();
                }
              }
            });
            return false;
          });

        

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Hero;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      
    }
    
  };
  
  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.